import React from "react"
import { graphql } from "gatsby"
import { compose, prop } from "ramda"
import { Container, Row } from "reactstrap"
import {
  getEdgesForQuery,
  getImageQueryFluidData,
  getQueryData,
  getSectionDataFor,
} from "@utils/utils"
import MainLayout from "@layouts/MainLayout/MainLayout"
import SEO from "@components/shared/SEO/SEO"
import HeaderSection from "@components/shared/HeaderSection/HeaderSection"
import CopySection from "@components/shared/CopySection/CopySection"
import GridList from "@components/shared/GridList/GridList"
import ContactSection from "@components/shared/ContactSection/ContactSection"
import SlaSection from "@components/services/SlaSection/SlaSection"
import MethodsSection from "@components/services/MethodsSection/MethodsSection"
import PartnersGallery from "@components/services/PartnersGallery/PartnersGallery"

/**
 * import pictures
 */

import imageServices from "@assets/pictures/buehne_leistungen.svg"
import imageSLA from "@assets/pictures/content_sla.svg"

import ic_projektmanagement from "@assets/icons/ic_projektmanagement.svg"
import ic_anforderungen from "@assets/icons/ic_anforderungen.svg"
import ic_entwicklung from "@assets/icons/ic_entwicklung.svg"
import ic_support from "@assets/icons/ic_support.svg"
import ic_ux from "@assets/icons/ic_ux-ui.svg"
import ic_testing from "@assets/icons/ic_testing.svg"

/**
 * quering data
 */

export const query = graphql`
  query ServicesSectionIndexQuery {
    content: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/services" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            subtitle
            title
            section
            blocks {
              image
              label
              text
            }
          }
          internal {
            content
          }
        }
      }
    }
    partners: allImageSharp(
      filter: { fluid: { originalName: { glob: "content_partner_*" } } }
      sort: { fields: resize___originalName }
    ) {
      edges {
        node {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    footer: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/ui" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            section
            title
            subtitle
            cta
            ctaUrl
          }
          internal {
            content
          }
          html
        }
      }
    }
  }
`

const ServicesPage = ({ data }) => {
  const content = compose(getQueryData, getEdgesForQuery("content"))(data)

  const headerSectionData = getSectionDataFor(
    "services-section-header",
    content
  )
  const howWeWorkSectionData = getSectionDataFor(
    "services-section-how-we-work",
    content
  )
  const whatWeDoSectionData = getSectionDataFor(
    "services-section-what-we-do",
    content
  )
  const whatWeDoBlockList = prop("blocks", whatWeDoSectionData)
  const whatWeDoImageList = {
    ic_projektmanagement,
    ic_anforderungen,
    ic_entwicklung,
    ic_support,
    ic_ux,
    ic_testing,
  }

  const methodsSectionData = getSectionDataFor(
    "services-section-methods",
    content
  )

  const partnersSectionData = getSectionDataFor(
    "services-section-partners",
    content
  )
  const partnersGalleryList = compose(
    getImageQueryFluidData,
    getEdgesForQuery("partners")
  )(data)

  const slaSectionData = getSectionDataFor("services-section-sla", content)

  const footer = compose(getQueryData, getEdgesForQuery("footer"))(data)
  const contactSectionData = getSectionDataFor(
    "ui-section-footer-contact",
    footer
  )

  return (
    <MainLayout>
      <SEO />
      <HeaderSection data={headerSectionData} image={imageServices} />
      <CopySection
        anchor={"how-we-work"}
        className="bg-blue-lighter"
        data={howWeWorkSectionData}
        dotColor="yellow"
      />
      <CopySection
        anchor={"what-we-do"}
        className="bg-blue-bright"
        data={whatWeDoSectionData}
        dotColor="green"
      >
        <Container className="mt-n5">
          <Row>
            <GridList items={whatWeDoBlockList} imageList={whatWeDoImageList} />
          </Row>
        </Container>
      </CopySection>
      <MethodsSection data={methodsSectionData} />
      <SlaSection data={slaSectionData} image={imageSLA} />
      <CopySection
        anchor={"partners"}
        className="bg-white"
        data={partnersSectionData}
        dotColor="green"
      >
        <>
          <PartnersGallery images={partnersGalleryList} className="mt-4" />
        </>
      </CopySection>
      <ContactSection data={contactSectionData} />
    </MainLayout>
  )
}

export default ServicesPage
