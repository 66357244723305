import "./PartnersGallery.scss"
import React from "react"
import Image from "gatsby-image"
import { Container, Row, Col } from "reactstrap"

const PartnersGallery = ({ images, ...props }) => (
  <Container id="partners-gallery" className="mt-n5" {...props}>
    <Row>
      <Col className="p-4">
        <Image fluid={images[0]} alt="Partner" />
      </Col>
      <Col className="p-4">
        <Image fluid={images[1]} alt="Partner" />
      </Col>
      <Col className="p-4">
        <Image fluid={images[2]} alt="Partner" />
      </Col>
    </Row>
    <Row>
      <Col className="p-4">
        <Image fluid={images[3]} alt="Partner" />
      </Col>
      <Col className="p-4">
        <Image fluid={images[4]} alt="Partner" />
      </Col>
      <Col className="p-4">
        <Image fluid={images[5]} alt="Partner" />
      </Col>
      <Col className="p-4">
        <Image fluid={images[6]} alt="Partner" />
      </Col>
    </Row>
  </Container>
)

export default PartnersGallery
