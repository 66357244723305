import React from "react"
import GridSection from "@components/shared/GridSection/GridSection"

export default ({ data: { subtitle, content }, image }) => (
  <GridSection anchor={"sla"} className="bg-lighter">
    <div className="section-grid-media">
      <img alt={subtitle} src={image}></img>
    </div>
    <div className="section-grid-copy">
      <div className="display-3 font-weight-bolder dot dot-red-light">
        {subtitle}
      </div>
      <div className="mt-3">{content}</div>
    </div>
  </GridSection>
)
